import {GET_BUYERDB_COMPANIES} from '@/base/api/buyerdb/company';
import {PickPathReq} from '@/base/api/interface';
import {FormData} from '@/base/api/interface/global';
import {ISicSugItem} from '@/base/api/buyerdb/sic/types';

export type IUsCompanyListFilterData = FormData<PickPathReq<typeof GET_BUYERDB_COMPANIES> & {
    subsic: PickPathReq<typeof GET_BUYERDB_COMPANIES>['sic'];
}>;

export interface SicSelectorNodeItem {
    isAll: boolean;
    label: string;
    value: any;
    id: any;
    leaf: boolean;
    children?: SicSelectorNodeItem[];
    parentId?: any;
}

export enum SEARCH_TAB_ENUM {
    'PRODUCT' = 'product',
    'SIC' = 'sic',
}

/**
 * 美国买家数据库行业附加信息
 */
export interface ISicSugItemExtraInfo {
    extraInfo?: {
        parentId?: ISicSugItem['id']; // 父行业id
        childIds?: Array<ISicSugItem['id']>; // 子行业id数组
        isAllChildIds?: boolean; // 是否包含了所有子行业id
    };
}

/**
 * 美国买家数据库行业信息（包括附加信息）
 */
export type ISicSugFullItem = ISicSugItem & ISicSugItemExtraInfo;

/**
 * 行业信息缓存
 */
export interface ISicSugCache {
    [id: string]: ISicSugFullItem;
}
