import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_ellipsis_text = _resolveComponent("ellipsis-text");
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel");
  const _component_client_only = _resolveComponent("client-only");
  const _component_el_popover = _resolveComponent("el-popover");
  const _directive_clickoutside = _resolveDirective("clickoutside");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createBlock(_component_el_popover, {
    ref: "ElPopoverRef",
    visible: _ctx.popperVisible,
    "onUpdate:visible": _cache[3] || (_cache[3] = $event => _ctx.popperVisible = $event),
    placement: "bottom-start",
    transition: "el-zoom-in-top",
    "popper-class": "sic-selector__cascader-dropdown",
    "gpu-acceleration": false,
    effect: "light",
    "manual-mode": "",
    width: "initial",
    "fallback-placements": ['bottom-start', 'top-start', 'right', 'left'],
    "stop-popper-mouse-event": false
  }, {
    reference: _withCtx(() => [_createVNode(_component_el_input, _mergeProps({
      ref: "ElInputRef",
      modelValue: _ctx.inputModelVal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.inputModelVal = $event),
      placeholder: "请输入产品名称、所在行业或公司名称查询"
    }, _ctx.$attrs, {
      onInput: _ctx.onTextInput,
      onClick: _cache[1] || (_cache[1] = $event => _ctx.togglePopperVisible(true))
    }), null, 16, ["modelValue", "onInput"])]),
    default: _withCtx(() => [_createVNode(_component_client_only, null, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_cascader_panel, {
        ref: "ElCascaderPanelRef",
        modelValue: _ctx.cascaderModelVal,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.cascaderModelVal = $event),
        options: _ctx.options,
        props: {
          lazy: true,
          lazyLoad: _ctx.lazyLoadNode
        },
        class: "sic-selector__cascader-panel",
        onChange: _ctx.onCascaderChange
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_ellipsis_text, {
          "show-after": 500
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.isAll ? '' : data.value + ' ') + _toDisplayString(data.label), 1)]),
          _: 2
        }, 1024)]),
        _: 1
      }, 8, ["modelValue", "options", "props", "onChange"])), [[_directive_clickoutside, () => _ctx.togglePopperVisible(false)], [_directive_loading, _ctx.searchLoading]])]),
      _: 1
    })]),
    _: 1
  }, 8, ["visible"]);
}