import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "company-name-column__info"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_t_image = _resolveComponent("t-image");
  const _component_ellipsis_text = _resolveComponent("ellipsis-text");
  const _component_el_table_column = _resolveComponent("el-table-column");
  return _openBlock(), _createBlock(_component_el_table_column, {
    label: "公司名称",
    "class-name": "company-name-column"
  }, {
    default: _withCtx(({
      row
    }) => [_createVNode(_component_t_image, {
      class: "company-name-column__img",
      "img-src": (row === null || row === void 0 ? void 0 : row.companyLogo) && _ctx.createProtocolUrl(row === null || row === void 0 ? void 0 : row.companyLogo),
      "placeholder-img-src": _ctx.getCompanyDefaultLogo(row === null || row === void 0 ? void 0 : row.companyName) || require('@/assets/img/customer/us-company-default-logo.png')
    }, null, 8, ["img-src", "placeholder-img-src"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_ellipsis_text, {
      "content-class": "company-name-column__name"
    }, {
      default: _withCtx(() => [_createElementVNode("span", {
        class: "empty-placeholder",
        onClick: _withModifiers($event => _ctx.handleNameClick(row), ["stop"])
      }, _toDisplayString(row === null || row === void 0 ? void 0 : row.companyName), 9, _hoisted_2)]),
      _: 2
    }, 1024)]), row !== null && row !== void 0 && row.recommendReason ? (_openBlock(), _createBlock(_component_ellipsis_text, {
      key: 0,
      "content-class": "company-name-column__recommend-reason",
      line: 2,
      "hide-immediately": true
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(row === null || row === void 0 ? void 0 : row.recommendReason), 1)]),
      _: 2
    }, 1024)) : _createCommentVNode("", true), _ctx.ignoreStatus ? (_openBlock(), _createElementBlock("p", {
      key: 1,
      class: _normalizeClass(_ctx.statusClass(row))
    }, _toDisplayString((row === null || row === void 0 ? void 0 : row.contactsCount) > 0 ? `已挖掘${row === null || row === void 0 ? void 0 : row.contactsCount}个联系人` : '未挖掘到联系人'), 3)) : (row === null || row === void 0 ? void 0 : row.miningStatus) !== _ctx.COMPANY_MINING_STATUS.NEVER ? (_openBlock(), _createElementBlock("p", {
      key: 2,
      class: _normalizeClass(_ctx.statusClass(row))
    }, [(row === null || row === void 0 ? void 0 : row.miningStatus) === _ctx.COMPANY_MINING_STATUS.FAILED ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createTextVNode("未挖掘到联系人")], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createTextVNode(_toDisplayString((row === null || row === void 0 ? void 0 : row.contactsCount) === 0 ? '联系人挖掘中' : `已挖掘${row === null || row === void 0 ? void 0 : row.contactsCount}个联系人`), 1)], 64))], 2)) : _createCommentVNode("", true), (row === null || row === void 0 ? void 0 : row.crmStatus) === _ctx.CRM_STATUS.IMPORTED ? (_openBlock(), _createElementBlock("p", {
      key: 3,
      class: _normalizeClass(`tag import-status ${_ctx.ignoreStatus || (row === null || row === void 0 ? void 0 : row.miningStatus) !== _ctx.COMPANY_MINING_STATUS.NEVER ? 'import-status--by-mining-status' : ''}`)
    }, " 已导入线索管理 ", 2)) : _createCommentVNode("", true)])]),
    _: 1
  });
}