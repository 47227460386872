import {PickPathReq} from '../../interface';
import * as PATHS from '.';
import {FETCH_API} from '@/base/http';

export function getBuyerdbSicSug(params: PickPathReq<typeof PATHS['GET_BUYERDB_SIC_SUG_API']>) {
    return FETCH_API.get(PATHS.GET_BUYERDB_SIC_SUG_API, {
        params,
        baseURL: process.env.VUE_APP_WAIMAO_OPEN_API,
        // proxyTarget: APP_CONFIG.hostMap.waimaoGateway,
    });
}
