import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_client_only = _resolveComponent("client-only");
  return _openBlock(), _createBlock(_component_client_only, null, {
    default: _withCtx(() => [_createVNode(_component_el_select, _mergeProps({
      modelValue: _ctx.value[_ctx.paramsKey],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value[_ctx.paramsKey] = $event),
      loading: _ctx.remoteLoading
    }, _ctx.$props.props, {
      onChange: _ctx.onChange
    }), {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.refOptions, opt => {
        return _openBlock(), _createBlock(_component_el_option, {
          key: opt.value,
          label: opt.label,
          value: opt.value
        }, null, 8, ["label", "value"]);
      }), 128))]),
      _: 1
    }, 16, ["modelValue", "loading", "onChange"])]),
    _: 1
  });
}