import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_ellipsis_text = _resolveComponent("ellipsis-text");
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel");
  const _component_client_only = _resolveComponent("client-only");
  const _component_el_popover = _resolveComponent("el-popover");
  const _directive_click_outside = _resolveDirective("click-outside");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createBlock(_component_el_popover, {
    ref: "popoverRef",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[3] || (_cache[3] = $event => _ctx.visible = $event),
    placement: "bottom-start",
    "popper-class": "sic-filter__popper",
    "popper-options": _ctx.popperOptions,
    width: "initial",
    disabled: _ctx.disabled
  }, {
    reference: _withCtx(() => [_createVNode(_component_el_input, {
      ref: "inputRef",
      modelValue: _ctx.inputModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.inputModel = $event),
      placeholder: "所在行业",
      clearable: "",
      disabled: _ctx.disabled,
      onClear: _ctx.handleInputClear,
      onClick: _cache[1] || (_cache[1] = _withModifiers($event => _ctx.togglePopperVisible(true), ["stop"]))
    }, null, 8, ["modelValue", "disabled", "onClear"])]),
    default: _withCtx(() => [_createVNode(_component_client_only, null, {
      default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_cascader_panel, {
        ref: "casRef",
        modelValue: _ctx.casModel,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.casModel = $event),
        class: "sic-filter__cas",
        options: _ctx.casOptions,
        props: _ctx.casProps,
        disabled: _ctx.disabled
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_ellipsis_text, {
          "show-after": 500
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.isAll ? '' : data.value + ' ') + _toDisplayString(data.label), 1)]),
          _: 2
        }, 1024)]),
        _: 1
      }, 8, ["modelValue", "options", "props", "disabled"])), [[_directive_click_outside, () => _ctx.togglePopperVisible(false)], [_directive_loading, _ctx.loading]])]),
      _: 1
    })]),
    _: 1
  }, 8, ["visible", "popper-options", "disabled"]);
}