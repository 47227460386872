import { defineComponent, ref } from 'vue';
// type TSelectOptionsProps = InstanceType<typeof ElOption>['$props']
export default defineComponent({
  name: 'TRemoteSelect',
  props: {
    paramsKey: {
      type: String,
      required: true
    },
    props: {
      type: Object
    },
    options: {
      type: Function,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['change'],
  setup(props, {
    emit
  }) {
    let refOptions = ref([]);
    let remoteLoading = ref(!props.options);
    const getOptions = async () => {
      if (typeof props.options === 'function') {
        try {
          remoteLoading.value = true;
          refOptions.value = await props.options();
        } finally {
          remoteLoading.value = false;
        }
      }
    };
    getOptions();
    const onChange = v => {
      emit('change', v);
    };
    return {
      refOptions,
      remoteLoading,
      onChange
    };
  }
});