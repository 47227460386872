import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withKeys as _withKeys, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-220698eb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search-home"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "search-container"
};
const _hoisted_4 = {
  class: "tab-container"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "search-item-container"
};
const _hoisted_7 = {
  class: "search-item input-wrapper"
};
const _hoisted_8 = {
  class: "search-item button-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_sic_selector = _resolveComponent("sic-selector");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: require('@/assets/img/customer/global-customs-data_bg.png')
  }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SEARCH_TABS, item => {
    return _openBlock(), _createElementBlock("div", {
      key: item.value,
      class: _normalizeClass(['tab', _ctx.activeTab === item.value ? 'active' : null]),
      onClick: $event => _ctx.handleTabClick(item)
    }, _toDisplayString(item.label), 11, _hoisted_5);
  }), 128))]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_ctx.activeTab === _ctx.SEARCH_TAB_ENUM.PRODUCT ? (_openBlock(), _createBlock(_component_el_input, {
    key: 0,
    modelValue: _ctx.filterData.product,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.filterData.product = $event),
    placeholder: "请用英文填写产品名称或HS Code",
    onKeydown: _cache[1] || (_cache[1] = _withKeys($event => !_ctx.disableSearchBtn && _ctx.emitSearch(), ["enter"]))
  }, null, 8, ["modelValue"])) : (_openBlock(), _createBlock(_component_sic_selector, {
    key: 1,
    sic: _ctx.filterData.sic,
    "onUpdate:sic": _cache[2] || (_cache[2] = $event => _ctx.filterData.sic = $event),
    "buyer-product": _ctx.filterData.buyerProduct,
    "onUpdate:buyerProduct": _cache[3] || (_cache[3] = $event => _ctx.filterData.buyerProduct = $event),
    onKeydown: _cache[4] || (_cache[4] = _withKeys($event => !_ctx.disableSearchBtn && _ctx.emitSearch(), ["enter"]))
  }, null, 8, ["sic", "buyer-product"]))]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_button, {
    disabled: _ctx.disableSearchBtn,
    type: "primary",
    onClick: _ctx.emitSearch
  }, {
    default: _withCtx(() => [_createTextVNode("查询")]),
    _: 1
  }, 8, ["disabled", "onClick"])])])])]);
}