import "core-js/modules/es.array.push.js";
/**
 * @file 表格的操作列，对 el-table-column 的二次封装，具有以下功能：
 * - 可以使用 props 快捷配置相关按钮
 * - 可以通过指定按钮个数来自动计算列宽
 * - 该列默认固定在表格列尾
 * - 继承了 el-table-column 的 props
 *
 * [Element-plus Bug]: 当 el-table 的 show-header 设置为 false 时，由于操作列处于 fixed 模式，因此会出现错位
 */
import { computed, defineComponent, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
const BUTTON_WIDTH = 30; // 操作按钮宽度
const BUTTON_GAP = 10; // 每个操作按钮之间的间隔
const CELL_PADDING_HOZ = 16; // 表格单元的水平内边距
const CELL_CONTENT_WIDTH_APPEND = 10; // safari 下的宽度要更宽一些
export default defineComponent({
  name: 'TTableOperationColumn',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '操作'
    },
    buttons: {
      type: Array,
      default: () => []
    },
    /**
     * 显式配置按钮数量，如果以 props 方式传入按钮配置，该配置无效
     */
    buttonCount: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    const {
      buttons,
      buttonCount,
      width
    } = toRefs(props);
    const router = useRouter();
    const columnRef = ref();
    const computedWidth = computed(() => {
      if (width.value) {
        return width.value;
      }
      const _buttonCount = buttons.value.length || buttonCount.value;
      return _buttonCount * BUTTON_WIDTH + (_buttonCount - 1) * BUTTON_GAP + CELL_PADDING_HOZ * 2 + CELL_CONTENT_WIDTH_APPEND;
    });
    const shouldDisabled = (button, row) => {
      if (button.disabled) {
        if (typeof button.disabled === 'function') {
          return button.disabled(row);
        }
        return button.disabled;
      }
      return false;
    };
    const shouldLoading = (button, row) => {
      if (button.loading) {
        if (typeof button.loading === 'function') {
          return button.loading(row);
        }
        return button.loading;
      }
      return false;
    };
    const handleButtonClick = (button, row) => {
      if (typeof button.onClick === 'function') {
        button.onClick(row);
      }
      if (button.goto) {
        router.push(typeof button.goto === 'function' ? button.goto(row) : button.goto);
      }
    };
    return {
      computedWidth,
      columnRef,
      shouldDisabled,
      shouldLoading,
      handleButtonClick
    };
  }
});