import { computed, defineComponent, h, inject, reactive, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { ElMessageBox } from 'element-plus';
import { SEARCH_TAB_ENUM } from '../types';
import CompanyInfoColumn from './CompanyInfoColumn.vue';
import CompanyNameColumn from './CompanyNameColumn.vue';
import SicSelector from './SicSelector.vue';
import SicFilter from './SicFilter.vue';
import { getBuyerDbCompanies, getBuyerDbCompanyFilterItems } from '@/base/api/buyerdb/company/methods';
import { COMPANY_MINING_STATUS } from '@/base/api/buyerdb/company/types';
import { useRequest } from '@/base/hooks/useGoodRequest';
import useTable from '@/base/hooks/useTable';
import { createProtocolUrl } from '@/base/utils/url';
import TFilter from '@/components/TFilter/index.vue';
import TLoadmore from '@/components/TLoadmore/index.vue';
import TTable from '@/components/TTable/index.vue';
import TTableOperationColumn from '@/components/TTable/TTableOperationColumn.vue';
import TTableSelectColumn from '@/components/TTable/TTableSelectColumn.vue';
import { loadInitSicOptions } from '@/views/Customer/UsCustomsData/utils';
// import {useLog} from '@/base/log/tjLog';
import { getQueryInfo } from '@/base/utils';
import { scLog } from '@/base/log/sclog';
import { INJECT_KEY__TO_TRIAL } from '@/views/components/Freetrial/injectKeys';
import { TRIAL_TYPE_ENUM } from '@/views/components/Freetrial/types';
export default defineComponent({
  name: 'SearchResult',
  components: {
    TFilter,
    TTable,
    SicSelector,
    TLoadmore,
    TTableOperationColumn,
    TTableSelectColumn,
    CompanyInfoColumn,
    CompanyNameColumn
  },
  props: {
    filterData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // const {send} = useLog();
    const route = useRoute();
    const {
      filterData
    } = toRefs(props);
    const TTableRef = ref();
    const sicSelectorRef = ref(null);
    /**
     * 当前主营产品、潜客行业组件绑定的值，只有当点击『搜索』按钮时，其值才会被写入 filterData 中
     */
    const bufferedFilterData = reactive({
      sic: undefined,
      product: undefined,
      buyerProduct: undefined
    });
    const selectedFilterTab = ref(SEARCH_TAB_ENUM.SIC);
    const {
      state: fetchedFilterOptionState
    } = useRequest(getBuyerDbCompanyFilterItems, {
      config: {
        axiosConfig: {
          cached: 'sessionStorage'
        }
      }
    });
    const selectedRows = ref([]);
    const searchDisabled = computed(() => {
      var _bufferedFilterData$s, _bufferedFilterData$b;
      if (selectedFilterTab.value === SEARCH_TAB_ENUM.PRODUCT) {
        var _bufferedFilterData$p;
        return !((_bufferedFilterData$p = bufferedFilterData.product) !== null && _bufferedFilterData$p !== void 0 && _bufferedFilterData$p.trim());
      }
      return !((_bufferedFilterData$s = bufferedFilterData.sic) !== null && _bufferedFilterData$s !== void 0 && _bufferedFilterData$s.trim()) && !((_bufferedFilterData$b = bufferedFilterData.buyerProduct) !== null && _bufferedFilterData$b !== void 0 && _bufferedFilterData$b.trim());
    });
    // 获取初始行业分类
    const initSicOptions = loadInitSicOptions(filterData.value.sic);
    /**
     * 在请求挖掘过程中禁用挖掘按钮
     */
    const disableMiningAction = ref(false);
    const pageSize = 20;
    const {
      tableData,
      tableLoading,
      fetchMoreDataLoading,
      loadMoreStatus,
      fetchTableData,
      fetchMoreData,
      currentPage
    } = useTable(getBuyerDbCompanies, {
      dataIncreaseMode: 'append',
      pageReqParam: 'pageNo',
      pageSizeReqParam: 'pageSize',
      defaultPageSize: pageSize,
      tableDataGetter: data => (data === null || data === void 0 ? void 0 : data.recordList) || [],
      onReadSearchQueryFromUrl: query => {
        let filterDataChanged = false;
        Object.keys(filterData.value).forEach(key => {
          if (query[key] !== filterData.value[key]) {
            filterData.value[key] = query[key];
            filterDataChanged = true;
          }
        });
        return filterDataChanged;
      },
      filterParams: computed(() => {
        const params = {
          ...filterData.value
        };
        return params;
      }),
      manual: true,
      beforeFetchTableData: requestParams => {
        // 修改请求数据，如果有子行业，优先使用子行业查询
        if (requestParams.subsic) {
          requestParams.sic = requestParams.subsic;
          delete requestParams.subsic;
        }
        return requestParams;
      },
      useRequestOption: {
        onSuccess: async res => {
          if (res && res.data) {
            if (typeof res.data.message === 'string' && res.data.message) {
              // eslint-disable-next-line @babel/new-cap
              await ElMessageBox({
                title: '提示',
                message: res.data.message
              });
            }
          }
          // 埋点
          let sendKey = tableData.value.length <= pageSize ? 'us_company_List_show' : 'us_load_more_show';
          let queryInfo = getQueryInfo();
          if (queryInfo.length) {
            queryInfo += '&';
          }
          queryInfo += `pageNum=${currentPage.value}`;
          scLog('click', {
            elem: sendKey
          });
          // send(sendKey, {
          //     queryInfo,
          // });
        }
      }
    });
    const filterItems = computed(() => {
      var _fetchedFilterOptionS, _fetchedFilterOptionS2;
      return [{
        type: 'select',
        key: 'establishTime',
        span: 4,
        options: ((_fetchedFilterOptionS = fetchedFilterOptionState.data) === null || _fetchedFilterOptionS === void 0 ? void 0 : _fetchedFilterOptionS.establishTimeList) || [],
        props: {
          placeholder: '成立时间',
          multiple: false,
          loading: fetchedFilterOptionState === null || fetchedFilterOptionState === void 0 ? void 0 : fetchedFilterOptionState.isLoading,
          clearable: true,
          disabled: disableMiningAction.value || tableLoading.value
        },
        onChange: () => {
          setTimeout(() => {
            scLog('click', {
              elem: 'us_establish_time_search_click'
            });
            // send('us_establish_time_search_click', {
            //     queryInfo: getQueryInfo(),
            // });
          }, 200);
        }
      }, {
        type: 'select',
        key: 'employeeCount',
        span: 4,
        options: ((_fetchedFilterOptionS2 = fetchedFilterOptionState.data) === null || _fetchedFilterOptionS2 === void 0 ? void 0 : _fetchedFilterOptionS2.employeeCountList) || [],
        props: {
          placeholder: '员工数',
          multiple: false,
          loading: fetchedFilterOptionState === null || fetchedFilterOptionState === void 0 ? void 0 : fetchedFilterOptionState.isLoading,
          clearable: true,
          disabled: disableMiningAction.value || tableLoading.value
        },
        onChange: () => {
          setTimeout(() => {
            scLog('click', {
              elem: 'us_employee_count_search_click'
            });
            // send('us_employee_count_search_click', {
            //     queryInfo: getQueryInfo(),
            // });
          }, 200);
        }
      }, {
        type: 'custom',
        key: 'subsic',
        span: 4,
        render: () => h(SicFilter, {
          parentSic: Object.keys(route.query).includes('sic') ? filterData.value.sic : '',
          subsic: filterData.value.subsic,
          initSicOptions: initSicOptions.value,
          disabled: disableMiningAction.value || tableLoading.value,
          'onUpdate:subsic': val => {
            var _TTableRef$value;
            if (!filterData.value.subsic && !val || filterData.value.subsic === val) {
              return;
            }
            filterData.value.subsic = val;
            fetchTableData({
              pageNo: 1
            }, {
              resetTableData: true
            });
            (_TTableRef$value = TTableRef.value) === null || _TTableRef$value === void 0 || (_TTableRef$value = _TTableRef$value.tableRef) === null || _TTableRef$value === void 0 || _TTableRef$value.clearSelection();
          }
        })
      }, {
        type: 'select',
        key: 'filterMining',
        span: 4,
        options: [{
          label: '未挖掘联系人',
          value: '1'
        }, {
          label: '联系人挖掘成功',
          value: '2'
        }, {
          label: '联系人挖掘中',
          value: '3'
        }, {
          label: '未挖掘到联系人',
          value: '4'
        }],
        props: {
          placeholder: '是否挖掘联系人',
          multiple: false,
          clearable: true,
          disabled: disableMiningAction.value || tableLoading.value
        },
        onChange: () => {
          setTimeout(() => {
            scLog('click', {
              elem: 'us_filter_mining_click'
            });
            // send('us_filter_mining_click', {
            //     queryInfo: getQueryInfo(),
            // });
          }, 200);
        }
      }, {
        type: 'select',
        key: 'filterCRM',
        span: 4,
        options: [{
          label: '已导入线索管理',
          value: '1'
        }, {
          label: '未导入线索管理',
          value: '2'
        }],
        props: {
          placeholder: '是否导入线索管理',
          multiple: false,
          clearable: true,
          disabled: disableMiningAction.value || tableLoading.value
        },
        onChange: () => {
          setTimeout(() => {
            scLog('click', {
              elem: 'us_filter_crm_click'
            });
            // send('us_filter_crm_click', {
            //     queryInfo: getQueryInfo(),
            // });
          }, 200);
        }
      }];
    });
    const handleSelectionChange = value => {
      selectedRows.value = value;
    };
    const setSelectedFilterTab = tab => {
      selectedFilterTab.value = tab;
    };
    const handleSearchBtnClick = () => {
      var _TTableRef$value2;
      if (selectedFilterTab.value === SEARCH_TAB_ENUM.PRODUCT) {
        filterData.value.sic = undefined;
        bufferedFilterData.sic = undefined;
        filterData.value.buyerProduct = undefined;
        bufferedFilterData.buyerProduct = undefined;
      } else {
        var _sicSelectorRef$value;
        filterData.value.product = undefined;
        bufferedFilterData.product = undefined;
        (_sicSelectorRef$value = sicSelectorRef.value) === null || _sicSelectorRef$value === void 0 || _sicSelectorRef$value.togglePopperVisible(false);
      }
      filterData.value.sic = bufferedFilterData.sic;
      filterData.value.buyerProduct = bufferedFilterData.buyerProduct;
      filterData.value.product = bufferedFilterData.product;
      fetchTableData({
        pageNo: 1
      }, {
        resetTableData: true
      });
      (_TTableRef$value2 = TTableRef.value) === null || _TTableRef$value2 === void 0 || (_TTableRef$value2 = _TTableRef$value2.tableRef) === null || _TTableRef$value2 === void 0 || _TTableRef$value2.clearSelection();
      if (selectedFilterTab.value === SEARCH_TAB_ENUM.SIC) {
        setTimeout(() => {
          scLog('click', {
            elem: 'us_buyer_product_search_click'
          });
          // send('us_buyer_product_search_click', {
          //     queryInfo: getQueryInfo(),
          // });
        }, 200);
      } else if (selectedFilterTab.value === SEARCH_TAB_ENUM.PRODUCT) {
        setTimeout(() => {
          scLog('click', {
            elem: 'us_product_search_click'
          });
          // send('us_product_search_click', {
          //     queryInfo: getQueryInfo(),
          // });
        }, 200);
      }
    };
    const onTFilterChange = () => {
      var _TTableRef$value3;
      // tab 自动切换回当前搜索的条件
      if (filterData.value.sic || filterData.value.buyerProduct) {
        setSelectedFilterTab(SEARCH_TAB_ENUM.SIC);
      } else {
        setSelectedFilterTab(SEARCH_TAB_ENUM.PRODUCT);
      }
      fetchTableData({
        pageNo: 1
      }, {
        resetTableData: true
      });
      (_TTableRef$value3 = TTableRef.value) === null || _TTableRef$value3 === void 0 || (_TTableRef$value3 = _TTableRef$value3.tableRef) === null || _TTableRef$value3 === void 0 || _TTableRef$value3.clearSelection();
    };
    function handleTabClick(item) {
      if (item.paneName === SEARCH_TAB_ENUM.SIC) {
        // setTimeout(() => {
        //     send('us_buyer_product_click', {
        //         queryInfo: getQueryInfo(),
        //     });
        // }, 200);
      } else if (item.paneName === SEARCH_TAB_ENUM.PRODUCT) {
        setTimeout(() => {
          scLog('click', {
            elem: 'us_product_click'
          });
          // send('us_product_click', {
          //     queryInfo: getQueryInfo(),
          // });
        }, 200);
      }
    }
    function handleCompanyNameClick({
      companyName
    }) {
      scLog('click', {
        elem: 'us_company_infor_btn_click'
      });
      // send('us_company_infor_btn_click', {
      //     companyName,
      //     queryInfo: getQueryInfo(),
      // });
    }
    function handleLoadMoreCurrentChange() {
      let queryInfo = getQueryInfo();
      if (queryInfo.length) {
        queryInfo += '&';
      }
      queryInfo += `pageNum=${currentPage.value + 1}`;
      scLog('click', {
        elem: 'us_load_more_click'
      });
      // send('us_load_more_click', {
      //     queryInfo,
      // });
      fetchMoreData();
    }
    /**
     * 申请美国买家数据库试用
     */
    const toTrial = inject(INJECT_KEY__TO_TRIAL);
    function handleDataTrial() {
      toTrial && toTrial({
        type: TRIAL_TYPE_ENUM.dataTrial
      });
      scLog('click', {
        elem: 'usdata-mining'
      });
    }
    watch(selectedFilterTab, val => {
      if (val === SEARCH_TAB_ENUM.SIC) {
        // setTimeout(() => {
        //     send('us_buyer_product_show', {
        //         queryInfo: getQueryInfo(),
        //     });
        // }, 200);
      } else if (val === SEARCH_TAB_ENUM.PRODUCT) {
        // setTimeout(() => {
        //     send('us_product_show', {
        //         queryInfo: getQueryInfo(),
        //     });
        // }, 200);
      }
    });
    watch([() => filterData.value.sic, () => filterData.value.product, () => filterData.value.buyerProduct], ([newSic, newProduct, newBuyerProduct]) => {
      bufferedFilterData.sic = newSic;
      bufferedFilterData.product = newProduct;
      bufferedFilterData.buyerProduct = newBuyerProduct;
    }, {
      immediate: true
    });
    return {
      // ref
      TTableRef,
      sicSelectorRef,
      // data
      selectedFilterTab,
      filterItems,
      tableData,
      selectedRows,
      searchDisabled,
      bufferedFilterData,
      disableMiningAction,
      COMPANY_MINING_STATUS,
      initSicOptions,
      // methods
      tableLoading,
      fetchMoreDataLoading,
      loadMoreStatus,
      fetchTableData,
      fetchMoreData,
      handleSelectionChange,
      setSelectedFilterTab,
      handleSearchBtnClick,
      onTFilterChange,
      createProtocolUrl,
      handleTabClick,
      handleCompanyNameClick,
      handleLoadMoreCurrentChange,
      handleDataTrial
    };
  }
});