import { computed, defineComponent, ref, toRefs, watch } from 'vue';
import { SEARCH_TAB_ENUM } from '../types';
import SicSelector from './SicSelector.vue';
// import {useLog} from '@/base/log/tjLog';
import { scLog } from '@/base/log/sclog';
const SEARCH_TABS = [{
  label: '潜客信息',
  value: SEARCH_TAB_ENUM.SIC
}, {
  label: '主营产品',
  value: SEARCH_TAB_ENUM.PRODUCT
}];
export default defineComponent({
  name: 'SearchHome',
  components: {
    SicSelector
  },
  props: {
    filterData: {
      type: Object,
      required: true
    }
  },
  emits: ['emit-search'],
  setup(props, {
    emit
  }) {
    // const {send} = useLog();
    const {
      filterData
    } = toRefs(props);
    const activeTab = ref(SEARCH_TAB_ENUM.SIC);
    const disableSearchBtn = computed(() => {
      var _filterData$value$sic, _filterData$value$buy;
      if (activeTab.value === SEARCH_TAB_ENUM.PRODUCT) {
        var _filterData$value$pro;
        return !((_filterData$value$pro = filterData.value.product) !== null && _filterData$value$pro !== void 0 && _filterData$value$pro.trim());
      }
      return !((_filterData$value$sic = filterData.value.sic) !== null && _filterData$value$sic !== void 0 && _filterData$value$sic.trim()) && !((_filterData$value$buy = filterData.value.buyerProduct) !== null && _filterData$value$buy !== void 0 && _filterData$value$buy.trim());
    });
    // 切换 Tab 时重置搜索
    watch(activeTab, () => {
      filterData.value.product = undefined;
      filterData.value.sic = undefined;
      filterData.value.buyerProduct = undefined;
    });
    const emitSearch = () => {
      var _filterData$value$sic2, _filterData$value$buy2, _filterData$value$pro2;
      // 检查是否有有效的搜索内容
      if (activeTab.value === SEARCH_TAB_ENUM.SIC && ((_filterData$value$sic2 = filterData.value.sic) !== null && _filterData$value$sic2 !== void 0 && _filterData$value$sic2.trim() || (_filterData$value$buy2 = filterData.value.buyerProduct) !== null && _filterData$value$buy2 !== void 0 && _filterData$value$buy2.trim()) || activeTab.value === SEARCH_TAB_ENUM.PRODUCT && (_filterData$value$pro2 = filterData.value.product) !== null && _filterData$value$pro2 !== void 0 && _filterData$value$pro2.trim()) {
        emit('emit-search');
        if (activeTab.value === SEARCH_TAB_ENUM.SIC) {
          scLog('click', {
            elem: 'us_buyer_product_show'
          });
          // send('us_buyer_product_search_click', {
          //     queryInfo: String(
          //         new URLSearchParams(returnNotEmptyValue(filterData.value) as Record<string, string>)
          //     ),
          // });
        } else if (activeTab.value === SEARCH_TAB_ENUM.PRODUCT) {
          scLog('click', {
            elem: 'us_product_search_click'
          });
          // send('us_product_search_click', {
          //     queryInfo: String(
          //         new URLSearchParams(returnNotEmptyValue(filterData.value) as Record<string, string>)
          //     ),
          // });
        }
      }
    };
    function handleTabClick(item) {
      if (item.value === SEARCH_TAB_ENUM.SIC) {
        scLog('click', {
          elem: 'us_buyer_product_click'
        });
        // send('us_buyer_product_click');
      } else if (item.value === SEARCH_TAB_ENUM.PRODUCT) {
        scLog('click', {
          elem: 'us_product_click'
        });
        // send('us_product_click');
      }
      activeTab.value = item.value;
    }
    watch(activeTab, val => {
      if (val === SEARCH_TAB_ENUM.SIC) {
        scLog('click', {
          elem: 'us_buyer_product_show'
        });
        // send('us_buyer_product_show');
      } else if (val === SEARCH_TAB_ENUM.PRODUCT) {
        scLog('click', {
          elem: 'us_product_show'
        });
        // send('us_product_show');
      }
    });
    return {
      // data
      SEARCH_TABS,
      SEARCH_TAB_ENUM,
      activeTab,
      disableSearchBtn,
      // methods
      emitSearch,
      handleTabClick
    };
  }
});