import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
import { useDebounceFn } from '@baidu/trade-util';
import { formatSicSearchResults, getSicSug } from '../utils';
import EllipsisText from '@/components/EllipsisText.vue';
import ClickOutside from '@/base/directives/click-outside';
import { scLog } from '@/base/log/sclog';
export default defineComponent({
  name: 'SicFilter',
  components: {
    EllipsisText
  },
  directives: {
    ClickOutside
  },
  props: {
    /**
     * 父行业，为空字符串表示没有父行业
     */
    parentSic: {
      type: String
    },
    /**
     * 选中的子行业
     */
    subsic: {
      type: String
    },
    /**
     * 初始化时使用的行业信息
     */
    initSicOptions: {
      type: Array
    },
    /**
     * 是否禁用
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:subsic'],
  setup(props, {
    emit
  }) {
    // const {send} = useLog();
    const {
      subsic
    } = toRefs(props);
    // Ref
    const popoverRef = ref(null);
    const inputRef = ref(null);
    const casRef = ref(null);
    // 输入框
    /**
     * 初始化时使用的输入框文本。当用户刷新页面时，可能在路由参数中包含更下一级的子行业信息时，仅依靠父行业信息无法获取显示文本的时候
     * 使用。且仅使用一次，用户选择其他子行业或清空输入框时将其重置为空字符串。
     */
    const initInputModel = ref('');
    /**
     * 输入框显示的内容
     */
    const inputModel = computed(() => {
      const node = findNode(subsic.value);
      return (node === null || node === void 0 ? void 0 : node.label) || initInputModel.value || '';
    });
    /**
     * 点击输入框清空按钮
     */
    function handleInputClear() {
      initInputModel.value = '';
      emit('update:subsic', '');
      setTimeout(() => {
        scLog('click', {
          elem: 'us_subsic_search_clear_click'
        });
        // send('us_subsic_search_click', {
        //     queryInfo: getQueryInfo(),
        // });
      }, 200);
    }
    // 级联面板
    /**
     * 是否显示级联面板
     */
    const visible = ref(false);
    /**
     * 级联面板是否正在初始化数据
     */
    const loading = ref(true);
    /**
     * 级联面板数据项
     */
    const casOptions = ref([]);
    /**
     * 级联面板当前项
     */
    const casModel = computed({
      get: () => subsic.value,
      set: val => {
        initInputModel.value = '';
        // 当用户选择子行业中的ALL时，输入框改为显示其父行业
        emit('update:subsic', Array.isArray(val) && val.length ? val[val.length - 1] : '');
        visible.value = false;
        setTimeout(() => {
          scLog('click', {
            elem: 'us_subsic_search_click'
          });
          // send('us_subsic_search_click', {
          //     queryInfo: getQueryInfo(),
          // });
        }, 200);
      }
    });
    /**
     * 级联面板属性
     */
    const casProps = {
      lazy: true,
      lazyLoad: casLazyLoad
    };
    /**
     * 懒加载级联面板子级行业信息
     * @param node
     * @param resolve
     */
    async function casLazyLoad(node, resolve) {
      if (node.root) {
        resolve([]);
      } else {
        var _popoverRef$value;
        const {
          data
        } = await getSicSug({
          parent_id: node.data.id,
          parent_sic: node.data.value
        });
        if (!Array.isArray(data) || data.length === 0) {
          resolve([]);
        }
        if (data[0].sic.toString() === node.data.value.toString()) {
          Object.assign(data[0], {
            isAll: true
          });
        }
        const children = formatSicSearchResults(data);
        node.data.children = children;
        resolve(children);
        // @ts-ignore
        nextTick((_popoverRef$value = popoverRef.value) === null || _popoverRef$value === void 0 ? void 0 : _popoverRef$value.update);
      }
    }
    /**
     * 根据sic从级联面板数据中获取对应的行业信息
     * @param sic
     */
    function findNode(sic) {
      if (!sic) {
        return null;
      }
      function subFindNode(nodes) {
        for (const node of nodes) {
          var _node$children;
          if (node.value === sic) {
            return node;
          }
          if ((_node$children = node.children) !== null && _node$children !== void 0 && _node$children.length) {
            const subNode = subFindNode(node.children);
            if (subNode) {
              return subNode;
            }
          }
        }
        return null;
      }
      return subFindNode(casOptions.value);
    }
    /**
     * 更新级联面板最小宽度，使其等于输入框宽度
     */
    function updateCasMinWidth() {
      var _inputRef$value;
      if (!inputRef.value || !casRef.value) {
        return;
      }
      const inputWidth = (_inputRef$value = inputRef.value) === null || _inputRef$value === void 0 ? void 0 : _inputRef$value.$el.offsetWidth;
      const casEl = casRef.value.$el;
      if (casEl) {
        casEl.style.minWidth = `${inputWidth}px`;
      }
    }
    const {
      run: updateCasMinWidthDebounced
    } = useDebounceFn(updateCasMinWidth, {
      wait: 100
    });
    // popper
    /**
     * popper选项
     */
    const popperOptions = {
      gpuAcceleration: false,
      effect: 'light',
      manualMode: true,
      fallbackPlacements: ['bottom-start', 'top-start', 'right', 'left'],
      stopPopperMouseEvent: false
    };
    /**
     * popper子实例，为了在点击其外部时隐藏popper时使用
     */
    const popperPaneRef = computed(() => {
      var _popoverRef$value2;
      return (_popoverRef$value2 = popoverRef.value) === null || _popoverRef$value2 === void 0 ? void 0 : _popoverRef$value2.popperRef;
    });
    /**
     * 切换popper的显隐状态
     * @param params
     */
    function togglePopperVisible(params) {
      visible.value = params !== null && params !== void 0 ? params : !visible.value;
    }
    // 监听初始化行业数据（在父组件发起业务请求，避免多个子组件重复请求）
    watch(() => props.initSicOptions, async val => {
      loading.value = false;
      if (!val || val.length !== 1 && val.length !== 2) {
        return;
      }
      if (val.length === 2) {
        // 有子节点时，只显示子节点
        casOptions.value = val[1].filter(item => !item.isAll);
      } else {
        // 没有子节点
        const parent = val[0].filter(item => !item.isAll);
        // 当第一个行业非子节点时（此时parentSic一般为空字符串），展示全部节点
        if (parent.length && !parent[0].leaf) {
          casOptions.value = parent;
        }
      }
      // 外部传入的有子节点信息，但是无法从级联面板数据中查找到，此时需要单独调用一次接口查询该子节点，用于在输入框中展示文本
      if (subsic.value && !findNode(subsic.value)) {
        try {
          const {
            data
          } = await getSicSug({
            sic: subsic.value || ''
          });
          initInputModel.value = Array.isArray(data) && data.length && data[0].category || '';
        } catch (error) {
          console.warn('获取当前行业分类失败', error);
        }
      }
    });
    // 监听父行业信息
    watch(() => props.parentSic, async val => {
      // 父行业变更时，重置相关数据
      initInputModel.value = '';
      emit('update:subsic', '');
      casOptions.value = [];
      loading.value = true;
      // 查询初始化数据（类似loadInitSicOptions）
      try {
        if (val) {
          // 潜客行业，有父节点
          const {
            data
          } = await getSicSug({
            sic: val || ''
          });
          const formatData = formatSicSearchResults(data).filter(item => !item.isAll);
          if (formatData.length) {
            const parent = formatData[0];
            if (!parent.leaf) {
              // 有子节点信息，继续查询
              const {
                data: childData
              } = await getSicSug({
                parent_id: parent.id,
                parent_sic: parent.value
              });
              if (childData.length && childData[0].sic.toString() === parent.value) {
                Object.assign(childData[0], {
                  isAll: true
                });
              }
              casOptions.value = formatSicSearchResults(childData).filter(item => !item.isAll);
            }
          }
        } else {
          // 主营产品，没有父节点
          const {
            data
          } = await getSicSug({});
          casOptions.value = formatSicSearchResults(data);
        }
      } catch (error) {
        console.warn('获取行业分类失败', error);
      } finally {
        loading.value = false;
      }
    });
    onMounted(() => {
      updateCasMinWidth();
      window.addEventListener('resize', updateCasMinWidthDebounced);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', updateCasMinWidthDebounced);
    });
    return {
      popoverRef,
      inputRef,
      casRef,
      popperPaneRef,
      inputModel,
      visible,
      loading,
      casOptions,
      casModel,
      casProps,
      popperOptions,
      togglePopperVisible,
      handleInputClear
    };
  }
});