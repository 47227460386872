import {Ref, ref} from 'vue';
import {cloneDeep} from 'lodash';
import {ISicSugCache, ISicSugFullItem, SicSelectorNodeItem} from './types';
import {ISicSugItem} from '@/base/api/buyerdb/sic/types';
import {getBuyerdbSicSug} from '@/base/api/buyerdb/sic/methods';
import {PickPathReq} from '@/base/api/interface';
import {TSuperCache} from '@/base/utils/cache';
import {
    CACHE_KEY_US_CUSTOMS_DATA_SIC_SUG,
    DEFAULT_US_CUSTOMS_DATA_SIC_SUG_CACHE,
    DEFAULT_US_CUSTOMS_DATA_SIC_SUG_FULL_ITEM,
} from '@/views/Customer/UsCustomsData/config';
import * as PATHS from '@/base/api/buyerdb/sic';

export function formatSicSearchResults(sicResults: ISicSugFullItem[]): SicSelectorNodeItem[] {
    return sicResults.map(item => {
        const res: SicSelectorNodeItem = {
            isAll: Object.prototype.hasOwnProperty.call(item, 'isAll'),
            label: item.category,
            value: item.sic,
            id: item.id,
            leaf: item.level > 3,
        };
        if (item.extraInfo?.parentId) {
            res.parentId = item.extraInfo.parentId;
        }
        return res;
    });
}

/**
 * 获取初始行业分类（当前行业和子行业的数组）
 * @param sic 父行业
 */
export function loadInitSicOptions(sic?: ISicSugItem['sic']): Ref<SicSelectorNodeItem[][]> {
    const result = ref<SicSelectorNodeItem[][]>([]);
    const options: SicSelectorNodeItem[][] = [];
    (async () => {
        try {
            if (sic) {
                // 潜客行业，有父节点
                const {data} = await getSicSug({
                    sic: sic || '',
                });
                const formatData = formatSicSearchResults(data);
                if (formatData.length) {
                    // 添加当前节点信息
                    options.push(formatData);
                    const parent = formatData[0];
                    if (!parent.leaf) {
                        const {data: childData} = await getSicSug({
                            parent_id: parent.id,
                            parent_sic: parent.value,
                        });
                        if (childData.length && childData[0].sic.toString() === parent.value) {
                            Object.assign(childData[0], {isAll: true});
                        }
                        // 添加子节点信息
                        options.push(formatSicSearchResults(childData));
                    }
                }
            }
            else {
                // 主营产品，没有父节点
                const {data} = await getSicSug({} as any);
                options.push(formatSicSearchResults(data));
            }
        }
        catch (error) {
            console.warn('获取初始行业分类失败', error);
        }
        finally {
            result.value = options;
        }
    })();
    return result;
}

/**
 * 获取行业信息缓存
 */
export function getSicSugCache(): ISicSugCache {
    const superCache = new TSuperCache();
    const cache = superCache.getItem(CACHE_KEY_US_CUSTOMS_DATA_SIC_SUG, 'sessionStorage');
    return cache || cloneDeep(DEFAULT_US_CUSTOMS_DATA_SIC_SUG_CACHE);
}

/**
 * 设置行业信息缓存
 * @param cache
 */
function setSicSugCache(cache: ISicSugCache) {
    const superCache = new TSuperCache();
    superCache.setItem(CACHE_KEY_US_CUSTOMS_DATA_SIC_SUG, cache, 'sessionStorage');
}

/**
 * 获取行业信息（包含缓存处理）
 * @param params
 */
export async function getSicSug(params: PickPathReq<(typeof PATHS)['GET_BUYERDB_SIC_SUG_API']>) {
    return new Promise<{
        data: ISicSugFullItem[];
    }>(async resolve => {
        const cache = getSicSugCache();

        /**
         * 更新缓存中的某个行业信息，并返回包含附加信息和行业信息
         * @param sug 某个行业信息
         * @param parentSugId 该行业信息的父行业id
         */
        function updateCacheSug(sug: ISicSugItem, parentSugId?: ISicSugItem['id']): ISicSugFullItem {
            let cacheSug = cache[sug.id];
            // 缓存里之前没有该行业信息，缓存对象先取默认值
            if (!cacheSug) {
                cacheSug = cloneDeep(DEFAULT_US_CUSTOMS_DATA_SIC_SUG_FULL_ITEM);
            }
            // 将行业信息中有值的字段赋值到缓存对象中
            for (const key of Object.keys(sug)) {
                const value = sug[key];
                if (value) {
                    cacheSug[key] = value;
                }
            }
            if (parentSugId) {
                cacheSug.extraInfo!.parentId = parentSugId;
            }
            cache[sug.id] = cacheSug;
            return cacheSug;
        }
        // 获取所有一级行业
        if (!Object.keys(params).length) {
            const rootSug = cache[0];
            // 当缓存中包含所有包含了所有子行业id时直接返回
            if (rootSug.extraInfo!.isAllChildIds) {
                const data = rootSug.extraInfo!.childIds?.map(id => cache[String(id)]) || [];
                return resolve({
                    data,
                });
            }
        }
        // 获取某父行业的所有子行业
        else if ('parent_id' in params && 'parent_sic' in params) {
            const parentSug = cache[params.parent_id];
            // 当缓存中包含该父行业信息，且包含了所有其子行业id时直接返回
            if (parentSug?.extraInfo?.isAllChildIds) {
                const data = parentSug.extraInfo.childIds?.map(id => cache[String(id)]) || [];
                // 在子行业列表中首位插入ALL项
                data.splice(0, 0, {
                    sic: parentSug.sic,
                    category: 'ALL',
                    id: parentSug.id,
                    level: 4,
                    extraInfo: {
                        parentId: parentSug.id,
                    },
                });
                return resolve({
                    data,
                });
            }
        }
        // 获取某行业
        else if ('sic' in params) {
            const sug = Object.values(cache).find(item => item.sic === params.sic);
            // 当缓存中包含该行业信息，且其行业分类有值时直接返回
            if (sug && sug.category) {
                const data = [sug];
                return resolve({
                    data,
                });
            }
        }
        // 从缓存获取失败，转为从网络获取数据
        const res = await getBuyerdbSicSug(params);
        // 当业务请求成功时
        if (!res.errno && Array.isArray(res.data) && res.data.length) {
            // 获取所有一级行业
            if (!Object.keys(params).length) {
                // 更新根行业信息
                const rootSug = cache[0];
                rootSug.extraInfo!.isAllChildIds = true;
                rootSug.extraInfo!.childIds = [];
                const data: ISicSugFullItem[] = [];
                for (const sug of res.data) {
                    rootSug.extraInfo!.childIds.push(sug.id);
                    // 更新子行业信息
                    data.push(updateCacheSug(sug, 0));
                }
                setSicSugCache(cache);
                return resolve({
                    data,
                });
            }
            // 获取某父行业的所有子行业
            else if ('parent_id' in params && 'parent_sic' in params) {
                let parentSug = cache[params.parent_id];
                // 缓存里之前没有该父行业信息，取默认值
                if (!parentSug) {
                    parentSug = cloneDeep(DEFAULT_US_CUSTOMS_DATA_SIC_SUG_FULL_ITEM);
                }
                // 更新父行业信息
                parentSug.sic = params.parent_sic;
                parentSug.id = +params.parent_id;
                parentSug.extraInfo!.isAllChildIds = true;
                parentSug.extraInfo!.childIds = [];
                const data: ISicSugFullItem[] = [];
                for (const sug of res.data) {
                    // 子行业信息数组中首位的ALL项
                    if (sug.id === parentSug.id) {
                        data.push({
                            ...sug,
                            extraInfo: {
                                parentId: parentSug.id,
                            },
                        });
                    }
                    // 除ALL项以外的子行业信息
                    else {
                        parentSug.extraInfo!.childIds!.push(sug.id);
                        // 更新子行业信息
                        data.push(updateCacheSug(sug, parentSug.id));
                    }
                }
                cache[parentSug.id] = parentSug;
                setSicSugCache(cache);
                return resolve({
                    data,
                });
            }
            // 获取某行业 && 模糊查询关键词

            const data: ISicSugFullItem[] = [];
            for (const sug of res.data) {
                // 更新行业信息
                data.push(updateCacheSug(sug));
            }
            setSicSugCache(cache);
            return resolve({
                data,
            });
        }
        return resolve(res);
    });
}

/**
 * 添加父行业分类至目标字符串前
 * @param cache 行业信息缓存
 * @param parentId 父行业id
 * @param target 目标字符串
 */
export function addParentCategory(cache: ISicSugCache, parentId: SicSelectorNodeItem['id'], target: string): string {
    if (!target) {
        target = '';
    }
    if (!cache || !parentId) {
        return target;
    }
    const parentSug = cache[parentId];
    if (!parentSug) {
        return target;
    }
    if (parentSug.category) {
        target = `${parentSug.category} / ${target}`;
    }
    if (parentSug.extraInfo?.parentId) {
        target = addParentCategory(cache, parentSug.extraInfo.parentId, target);
    }
    return target;
}
