import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ellipsis_text = _resolveComponent("ellipsis-text");
  const _component_el_table_column = _resolveComponent("el-table-column");
  return _openBlock(), _createBlock(_component_el_table_column, {
    label: "公司信息",
    "class-name": "company-info-column"
  }, {
    default: _withCtx(({
      row
    }) => [_createVNode(_component_ellipsis_text, null, {
      default: _withCtx(() => [_createTextVNode("成立时间：" + _toDisplayString((row === null || row === void 0 ? void 0 : row.establishTime) || '-'), 1)]),
      _: 2
    }, 1024), _createVNode(_component_ellipsis_text, null, {
      default: _withCtx(() => [_createTextVNode("员工人数：" + _toDisplayString((row === null || row === void 0 ? void 0 : row.employeeCount) || '-'), 1)]),
      _: 2
    }, 1024), _createVNode(_component_ellipsis_text, null, {
      default: _withCtx(() => [_createTextVNode("年销售额：" + _toDisplayString((row === null || row === void 0 ? void 0 : row.salesAmount) || '-'), 1)]),
      _: 2
    }, 1024), _createVNode(_component_ellipsis_text, null, {
      default: _withCtx(() => [_createTextVNode("所在行业：" + _toDisplayString((row === null || row === void 0 ? void 0 : row.fullSic) || '-'), 1)]),
      _: 2
    }, 1024)]),
    _: 1
  });
}