import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-47c63afc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "t-loadmore"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_t_button = _resolveComponent("t-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.status === 'loading' ? (_openBlock(), _createBlock(_component_t_button, {
    key: 0,
    loading: true
  }, {
    default: _withCtx(() => [_createTextVNode("正在加载中...")]),
    _: 1
  })) : _ctx.status === 'end' ? (_openBlock(), _createBlock(_component_t_button, {
    key: 1,
    disabled: true
  }, {
    default: _withCtx(() => [_createTextVNode("没有更多了")]),
    _: 1
  })) : (_openBlock(), _createBlock(_component_t_button, {
    key: 2,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('current-change'))
  }, {
    default: _withCtx(() => [_createTextVNode("加载更多")]),
    _: 1
  }))]);
}