import "core-js/modules/es.error.cause.js";
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, toRefs, watch } from 'vue';
import { useDebounceFn } from '@baidu/trade-util';
import { addParentCategory, formatSicSearchResults, getSicSug, getSicSugCache } from '../utils';
import Clickoutside from '@/base/directives/click-outside';
import EllipsisText from '@/components/EllipsisText.vue';
const NO_DATA_DESC = '暂无数据';
const NEED_INPUT_KEYWORD_DESC = '请输入关键词进行检索';
const NEED_INPUT_MORE_DESC = '请输入更多的关键词';
export default defineComponent({
  name: 'SicSelector',
  directives: {
    Clickoutside
  },
  props: {
    sic: {
      type: [String, Number],
      default: undefined
    },
    buyerProduct: {
      type: String,
      default: undefined
    },
    /**
     * 初次加载的行业信息
     */
    initSicOptions: {
      type: Array
    }
  },
  emits: ['update:sic', 'update:buyerProduct'],
  expose: ['togglePopperVisible'],
  setup(props, {
    emit
  }) {
    const {
      sic,
      buyerProduct
    } = toRefs(props);
    const ElInputRef = ref();
    const ElCascaderPanelRef = ref();
    const ElPopoverRef = ref();
    const inputModelVal = ref(buyerProduct.value || '');
    const options = ref([]);
    const cascaderModelVal = ref();
    const popperVisible = ref(false);
    const searchLoading = ref(false);
    const ElPopperPaneRef = computed(() => {
      var _ElPopoverRef$value;
      return (_ElPopoverRef$value = ElPopoverRef.value) === null || _ElPopoverRef$value === void 0 ? void 0 : _ElPopoverRef$value.popperRef;
    });
    const updatePopperPosition = () => {
      var _ElPopoverRef$value2;
      // @ts-ignore
      nextTick((_ElPopoverRef$value2 = ElPopoverRef.value) === null || _ElPopoverRef$value2 === void 0 ? void 0 : _ElPopoverRef$value2.update);
    };
    const setCascaderEmptyText = text => {
      if (ElCascaderPanelRef.value) {
        const emptyTextDom = ElCascaderPanelRef.value.$el.querySelector('.el-cascader-menu__empty-text');
        if (emptyTextDom) {
          emptyTextDom.innerText = text;
        }
      }
    };
    /**
     * 设置 Cascader 面板的宽度为 input 框的宽度
     */
    const setCascaderPanelWidth = () => {
      var _ElInputRef$value;
      if (!ElInputRef.value || !ElCascaderPanelRef.value) {
        return;
      }
      const inputWidth = (_ElInputRef$value = ElInputRef.value) === null || _ElInputRef$value === void 0 ? void 0 : _ElInputRef$value.$el.offsetWidth;
      const cascaderPanelDom = ElCascaderPanelRef.value.$el;
      if (cascaderPanelDom) {
        cascaderPanelDom.style.width = `${inputWidth}px`;
        cascaderPanelDom.style.maxWidth = `${inputWidth}px`;
      }
    };
    const {
      run: setCascaderPanelWidthDebounced
    } = useDebounceFn(setCascaderPanelWidth, {
      wait: 100
    });
    const togglePopperVisible = visible => {
      var _visible;
      visible = (_visible = visible) !== null && _visible !== void 0 ? _visible : !popperVisible.value;
      if (visible !== popperVisible.value) {
        popperVisible.value = visible;
        if (visible) {
          setCascaderPanelWidth();
          updatePopperPosition();
          nextTick(() => {
            if (inputModelVal.value.trim()) {
              setCascaderEmptyText(NO_DATA_DESC);
            } else {
              setCascaderEmptyText(NEED_INPUT_KEYWORD_DESC);
            }
          });
        }
        if (!visible) {
          var _ElInputRef$value2;
          (_ElInputRef$value2 = ElInputRef.value) === null || _ElInputRef$value2 === void 0 || _ElInputRef$value2.blur();
        } else {
          var _ElInputRef$value3;
          (_ElInputRef$value3 = ElInputRef.value) === null || _ElInputRef$value3 === void 0 || _ElInputRef$value3.focus();
        }
      }
    };
    const searchKeywords = async val => {
      // 用户输入sic编码的话，两位字符就触发搜索；否则需要三位字符才触发搜索
      if (/^[0-9]*$/.test(val) && val.length >= 2 || val.length >= 3) {
        try {
          searchLoading.value = true;
          const result = (await getSicSug({
            query: val
          })).data;
          if (!(result instanceof Array)) {
            throw new Error();
          }
          options.value = formatSicSearchResults(result);
        } catch {
          options.value = [];
        } finally {
          searchLoading.value = false;
        }
        nextTick(() => setCascaderEmptyText(NO_DATA_DESC));
      } else {
        if (val.length === 0) {
          nextTick(() => setCascaderEmptyText(NEED_INPUT_KEYWORD_DESC));
        } else {
          nextTick(() => setCascaderEmptyText(NEED_INPUT_MORE_DESC));
        }
        options.value = [];
        searchLoading.value = false;
      }
    };
    const {
      run: searchKeywordsDebounced
    } = useDebounceFn(searchKeywords);
    const onTextInput = async val => {
      var _ElCascaderPanelRef$v;
      searchLoading.value = true;
      // 文本一旦变动当前选中项就被清空
      (_ElCascaderPanelRef$v = ElCascaderPanelRef.value) === null || _ElCascaderPanelRef$v === void 0 || _ElCascaderPanelRef$v.clearCheckedNodes();
      emit('update:sic', undefined);
      emit('update:buyerProduct', val.trim());
      searchKeywordsDebounced(val.trim());
    };
    const onCascaderChange = val => {
      if (val) {
        var _ElCascaderPanelRef$v2;
        const selectedNode = (_ElCascaderPanelRef$v2 = ElCascaderPanelRef.value) === null || _ElCascaderPanelRef$v2 === void 0 ? void 0 : _ElCascaderPanelRef$v2.getCheckedNodes(false);
        if (selectedNode && selectedNode.length > 0) {
          const nodeItem = selectedNode[0].data;
          // 优先从缓存中逐级查找父行业设置标题
          if (nodeItem) {
            let title = nodeItem.label || '';
            if (nodeItem.parentId) {
              const cache = getSicSugCache();
              // 添加父行业分类
              title = addParentCategory(cache, nodeItem.parentId, title);
            }
            inputModelVal.value = title;
          }
          // 否则直接使用 pathLabels 字段设置标题（兜底逻辑）
          else {
            inputModelVal.value = selectedNode[0].pathLabels.join(' / ');
          }
          emit('update:sic', selectedNode[0].value);
          emit('update:buyerProduct', undefined);
        }
      }
    };
    const lazyLoadNode = async (node, resolve) => {
      if (node.root) {
        resolve([]);
      } else {
        const result = (await getSicSug({
          parent_id: node.data.id,
          parent_sic: node.data.value
        })).data;
        if (!(result instanceof Array) || result.length === 0) {
          resolve([]);
        }
        if (result[0].sic.toString() === node.data.value.toString()) {
          Object.assign(result[0], {
            isAll: true
          });
        }
        resolve(formatSicSearchResults(result));
      }
    };
    // 监控initSicOptions字段
    watch(() => props.initSicOptions, val => {
      if (!sic.value || !val) {
        // sic 是个无效值
        emit('update:sic', undefined);
        options.value = [];
        inputModelVal.value = buyerProduct.value || '';
        cascaderModelVal.value = undefined;
        return;
      }
      const mv = sic.value.toString();
      if (val.length === 1) {
        // 叶子节点
        cascaderModelVal.value = [mv];
        options.value = val[0];
      } else if (val.length === 2) {
        // 非叶子节点
        cascaderModelVal.value = [mv, mv];
        const parent = val[0][0];
        parent.children = val[1];
        options.value = val[0];
      }
      nextTick(() => {
        onCascaderChange(cascaderModelVal.value);
      });
    });
    /**
     * 当 modelValue 有初始值时触发（已废弃，业务请求提取到父组件调用，避免多个子组件重复请求）
     */
    /*
    const checkModelValue = async () => {
        if (!modelValue.value) {
            return;
        }
        try {
            inputDisable.value = true;
            const mv = modelValue.value.toString();
            const result = (await getSicSug({sic: mv})).data;
            if (result instanceof Array && result.length) {
                const fetchedOptions = formatSicSearchResults(result);
                const fetchedOption = fetchedOptions[0];
                let cascaderVal: string[];
                // 这个节点如果不是叶子节点，则需要加载子节点
                if (!fetchedOption.leaf) {
                    const result = (
                        await getSicSug({parent_id: fetchedOption.id, parent_sic: fetchedOption.value})
                    ).data;
                    if (result[0].sic.toString() === mv) {
                        Object.assign(result[0], {isAll: true});
                    }
                    const fetchedSubOptions = formatSicSearchResults(result);
                    fetchedOption.children = fetchedSubOptions;
                    cascaderVal = [mv, mv];
                }
                else {
                    cascaderVal = [mv];
                }
                cascaderModelVal.value = cascaderVal;
                options.value = fetchedOptions;
                nextTick(() => {
                    onCascaderChange(cascaderVal);
                });
            }
            else {
                throw new Error();
            }
        }
        catch {
            // sic 是个无效值
            emit('update:modelValue', undefined);
            options.value = [];
            inputModelVal.value = '';
            cascaderModelVal.value = undefined;
        }
        finally {
            inputDisable.value = false;
        }
    };
     */
    watch(sic, newVal => {
      if (newVal === undefined) {
        options.value = [];
        inputModelVal.value = buyerProduct.value || '';
        cascaderModelVal.value = undefined;
      }
    });
    onMounted(() => {
      setCascaderPanelWidth();
      window.addEventListener('resize', setCascaderPanelWidthDebounced);
      // checkModelValue();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', setCascaderPanelWidthDebounced);
    });
    return {
      // ref
      ElInputRef,
      ElCascaderPanelRef,
      ElPopoverRef,
      ElPopperPaneRef,
      // data
      options,
      inputModelVal,
      cascaderModelVal,
      popperVisible,
      searchLoading,
      // methods
      onTextInput,
      searchKeywords,
      searchKeywordsDebounced,
      onCascaderChange,
      lazyLoadNode,
      togglePopperVisible
    };
  },
  components: {
    EllipsisText
  }
});