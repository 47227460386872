import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "tfilter t-filter-wrapper"
};
const _hoisted_2 = {
  key: 0,
  class: "tfilter__label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_t_search_input = _resolveComponent("t-search-input");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_client_only = _resolveComponent("client-only");
  const _component_remote_select = _resolveComponent("remote-select");
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_button = _resolveComponent("el-radio-button");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_v_node_render = _resolveComponent("v-node-render");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    gutter: 10,
    pull: 0,
    align: "middle",
    justify: "start"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, item => {
      return _openBlock(), _createBlock(_component_el_col, _mergeProps({
        key: item.label,
        span: item.span || {
          search: 8,
          date: 10,
          select: 8,
          radio: 6
        }[item.type]
      }, item.colProps, {
        class: "tfilter__item"
      }), {
        default: _withCtx(() => [item.type === 'search' ? (_openBlock(), _createBlock(_component_t_search_input, _mergeProps({
          key: 0,
          modelValue: _ctx.value[item.key],
          "onUpdate:modelValue": $event => _ctx.value[item.key] = $event,
          label: item.label,
          placeholder: item.placeholder
        }, item.props, {
          onRequestSearch: $event => _ctx.onChange(item)
        }), null, 16, ["modelValue", "onUpdate:modelValue", "label", "placeholder", "onRequestSearch"])) : _createCommentVNode("", true), item.type === 'select' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [item.label ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.label), 1)) : _createCommentVNode("", true), Array.isArray(item.options) ? (_openBlock(), _createBlock(_component_client_only, {
          key: 1
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, _mergeProps({
            modelValue: _ctx.value[item.key],
            "onUpdate:modelValue": $event => _ctx.value[item.key] = $event,
            placeholder: item.placeholder,
            multiple: ""
          }, item.props, {
            onChange: $event => _ctx.onChange(item)
          }), {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, opt => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: opt.value,
                label: opt.label,
                value: opt.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 2
          }, 1040, ["modelValue", "onUpdate:modelValue", "placeholder", "onChange"])]),
          _: 2
        }, 1024)) : typeof item.options === 'function' ? (_openBlock(), _createBlock(_component_remote_select, _mergeProps({
          key: 2,
          "params-key": item.key,
          value: _ctx.value,
          options: item.options,
          placeholder: item.placeholder
        }, item.props, {
          onChange: $event => _ctx.onChange(item)
        }), null, 16, ["params-key", "value", "options", "placeholder", "onChange"])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), item.type === 'date' ? (_openBlock(), _createBlock(_component_el_date_picker, _mergeProps({
          key: 2,
          modelValue: _ctx.value[item.key],
          "onUpdate:modelValue": $event => _ctx.value[item.key] = $event,
          type: "daterange"
        }, item.props, {
          "start-placeholder": "开始时间",
          "end-placeholder": "结束时间",
          "range-separator": "至",
          onChange: $event => _ctx.onChangeDate(item, $event)
        }), null, 16, ["modelValue", "onUpdate:modelValue", "onChange"])) : _createCommentVNode("", true), item.type === 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, _mergeProps({
          key: 3,
          modelValue: _ctx.value[item.key],
          "onUpdate:modelValue": $event => _ctx.value[item.key] = $event
        }, item.props), {
          default: _withCtx(() => [item.optionType === 'radio' ? (_openBlock(true), _createElementBlock(_Fragment, {
            key: 0
          }, _renderList(item.options, option => {
            return _openBlock(), _createBlock(_component_el_radio, {
              key: option.value,
              label: option.value
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(option.label), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
            key: 1
          }, _renderList(item.options, option => {
            return _openBlock(), _createBlock(_component_el_radio_button, {
              key: option.value,
              class: "plain",
              label: option.value
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(option.label), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 2
        }, 1040, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("", true), item.type === 'custom' ? (_openBlock(), _createBlock(_component_v_node_render, {
          key: 4,
          vnode: _ctx.getItemRender(item)
        }, null, 8, ["vnode"])) : _createCommentVNode("", true)]),
        _: 2
      }, 1040, ["span"]);
    }), 128)), _ctx.$slots.buttons ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: _normalizeClass(['tfilter--left', _ctx.buttonAlign === 'left' ? 'align-left' : 'align-right'])
    }, [_renderSlot(_ctx.$slots, "buttons")], 2)) : _createCommentVNode("", true)]),
    _: 3
  })]);
}