import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search_home = _resolveComponent("search-home");
  const _component_search_result = _resolveComponent("search-result");
  return !_ctx.triggeredSearch ? (_openBlock(), _createBlock(_component_search_home, {
    key: 0,
    "filter-data": _ctx.filterData,
    onEmitSearch: _ctx.onEmitSearch
  }, null, 8, ["filter-data", "onEmitSearch"])) : (_openBlock(), _createBlock(_component_search_result, {
    key: 1,
    ref: "searchResultRef",
    "filter-data": _ctx.filterData
  }, null, 8, ["filter-data"]));
}