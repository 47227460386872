import { defineComponent, nextTick, onActivated, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { SEARCH_TAB_ENUM } from './types';
import SearchHome from './components/SearchHome.vue';
import SearchResult from './components/SearchResult.vue';
import { DEFAULT_US_COMPANY_LIST_FILTER_DATA, DEFAULT_PAGE_SIZE } from '@/views/Customer/UsCustomsData/config';
import { scLog } from '@/base/log/sclog';
// import {useLog} from '@/base/log';
export default defineComponent({
  name: 'UsCustomsData',
  components: {
    SearchHome,
    SearchResult
  },
  setup() {
    // const {send} = useLog();
    // 是否已经触发搜索
    const triggeredSearch = ref(false);
    const filterData = reactive(Object.assign({}, DEFAULT_US_COMPANY_LIST_FILTER_DATA));
    const searchResultRef = ref(null);
    const route = useRoute();
    const router = useRouter();
    let searchResultScrollTop = 0;
    const onEmitSearch = () => {
      triggeredSearch.value = true;
      nextTick(() => {
        var _searchResultRef$valu;
        (_searchResultRef$valu = searchResultRef.value) === null || _searchResultRef$valu === void 0 || _searchResultRef$valu.fetchTableData({
          pageNo: 1,
          pageSize: DEFAULT_PAGE_SIZE
        }, {
          routeMode: 'replace'
        });
        if (filterData.product) {
          var _searchResultRef$valu2;
          (_searchResultRef$valu2 = searchResultRef.value) === null || _searchResultRef$valu2 === void 0 || _searchResultRef$valu2.setSelectedFilterTab(SEARCH_TAB_ENUM.PRODUCT);
        }
      });
    };
    onMounted(() => {
      const urlQuery = route.query;
      const urlQueryKeys = Object.keys(urlQuery);
      // 检查是否有有效的 url query，如果有直接触发搜索
      if (Object.keys(filterData).some(item => urlQueryKeys.includes(item))) {
        triggeredSearch.value = true;
        // 读取 url query 中的参数，传递到过滤器中
        Object.keys(filterData).forEach(key => {
          if (urlQuery[key]) {
            filterData[key] = urlQuery[key];
          }
        });
        if (urlQueryKeys.includes('product')) {
          nextTick(() => {
            var _searchResultRef$valu3;
            return (_searchResultRef$valu3 = searchResultRef.value) === null || _searchResultRef$valu3 === void 0 ? void 0 : _searchResultRef$valu3.setSelectedFilterTab(SEARCH_TAB_ENUM.PRODUCT);
          });
        }
        // 触发搜索
        nextTick(() => {
          var _searchResultRef$valu4;
          (_searchResultRef$valu4 = searchResultRef.value) === null || _searchResultRef$valu4 === void 0 || _searchResultRef$valu4.fetchTableData({
            pageNo: 1,
            pageSize: DEFAULT_PAGE_SIZE
          }, {
            routeMode: 'replace'
          });
        });
      } else {
        scLog('click', {
          elem: 'us_buyer_product_show'
        });
        // send('us_buyer_product_show');
      }
    });
    router.beforeEach(to => {
      if (!Object.keys(to.query).length && to.name === route.name) {
        triggeredSearch.value = false;
        Object.assign(filterData, DEFAULT_US_COMPANY_LIST_FILTER_DATA);
      }
      if (to.name === 'UsCustomsDataDetail') {
        var _searchResultRef$valu5;
        searchResultScrollTop = ((_searchResultRef$valu5 = searchResultRef.value) === null || _searchResultRef$valu5 === void 0 ? void 0 : _searchResultRef$valu5.$el.scrollTop) || 0;
      }
    });
    onActivated(() => {
      if (searchResultScrollTop && searchResultRef.value) {
        searchResultRef.value.$el.scrollTop = searchResultScrollTop;
      }
      searchResultScrollTop = 0;
    });
    return {
      filterData,
      onEmitSearch,
      triggeredSearch,
      searchResultRef
    };
  }
});