import { defineComponent } from 'vue';
import TButton from '@/components/TButton/index.vue';
export default defineComponent({
  name: 'TLoadmore',
  components: {
    TButton
  },
  props: {
    status: {
      type: String,
      default: 'pendding'
    }
  }
});