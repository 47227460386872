import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { COMPANY_MINING_STATUS, CRM_STATUS } from '@/base/api/buyerdb/company/types';
import { createProtocolUrl } from '@/base/utils/url';
import EllipsisText from '@/components/EllipsisText.vue';
import TImage from '@/components/TImage.vue';
import { getCompanyDefaultLogo } from '@/views/Customer/utils';
export default defineComponent({
  name: 'CompanyNameColumn',
  components: {
    TImage,
    EllipsisText
  },
  props: {
    /**
     * 是否忽略状态字段，直接根据联系人数量字段显示文案
     */
    ignoreStatus: {
      type: Boolean,
      default: false
    },
    /**
     * 筛选条件，透传至企业详情页用
     */
    filterData: {
      type: Object
    }
  },
  emits: ['nameClick'],
  setup(props, {
    emit
  }) {
    const router = useRouter();
    function handleNameClick(row) {
      var _props$filterData, _props$filterData2, _props$filterData3;
      emit('nameClick', row);
      router.push({
        name: 'UsCustomsDataDetail',
        params: {
          id: row.companyId
        },
        query: {
          sic: (_props$filterData = props.filterData) === null || _props$filterData === void 0 ? void 0 : _props$filterData.sic,
          buyerProduct: (_props$filterData2 = props.filterData) === null || _props$filterData2 === void 0 ? void 0 : _props$filterData2.buyerProduct,
          product: (_props$filterData3 = props.filterData) === null || _props$filterData3 === void 0 ? void 0 : _props$filterData3.product
        }
      });
    }
    function statusClass(item) {
      let res = 'tag mining-status';
      if (props.ignoreStatus) {
        res += item.contactsCount > 0 ? ' mining-status-success' : ' mining-status-fail';
      } else if (item.miningStatus !== COMPANY_MINING_STATUS.NEVER) {
        if (item.miningStatus === COMPANY_MINING_STATUS.FAILED) {
          res += ' mining-status-fail';
        } else {
          res += item.contactsCount === 0 ? ' mining-status-process' : ' mining-status-success';
        }
      }
      return res;
    }
    return {
      COMPANY_MINING_STATUS,
      CRM_STATUS,
      createProtocolUrl,
      getCompanyDefaultLogo,
      handleNameClick,
      statusClass
    };
  }
});