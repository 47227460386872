import "core-js/modules/es.array.push.js";
import { ElDatePicker, ElMessageBox, ElSelect } from 'element-plus';
import dayjs from 'dayjs';
import { defineComponent, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import TSearchInput from './components/TSearchInput.vue';
import RemoteSelect from './RemoteSelect.vue';
import VNodeRender from '@/components/VNodeRender/index.vue';
import { returnNotEmptyValue } from '@/base/utils/object';
import { scLog } from '@/base/log/sclog';
export default defineComponent({
  name: 'TFilter',
  components: {
    ElSelect,
    ElDatePicker,
    VNodeRender,
    RemoteSelect,
    TSearchInput
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    replaceOnQuery: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    buttonAlign: {
      type: String,
      default: 'right'
    }
  },
  emits: ['change', 'clear'],
  setup(props, {
    emit
  }) {
    const router = useRouter();
    const refsProps = toRefs(props);
    const onChange = item => {
      if (props.replaceOnQuery) {
        router.push({
          query: returnNotEmptyValue(refsProps.value.value)
        });
      }
      scLog('click', {
        elem: 'filter-changed'
      });
      emit('change', refsProps.value.value);
      if (typeof (item === null || item === void 0 ? void 0 : item.onChange) === 'function') {
        item.onChange();
      }
    };
    const onChangeDate = (dateProps, event) => {
      const {
        alias,
        aliasDataFormat
      } = dateProps;
      const [start = 'start_time', end = 'end_time'] = alias || [];
      const sTime = event === null || event === void 0 ? void 0 : event[0];
      const eTime = event === null || event === void 0 ? void 0 : event[1];
      function getAliasTimeValue(time, type) {
        if (time) {
          if (typeof aliasDataFormat === 'string') {
            return dayjs(time).format(aliasDataFormat);
          } else if (typeof aliasDataFormat === 'function') {
            return aliasDataFormat(time, type);
          }
          return dayjs(time).unix();
        }
        return undefined;
      }
      refsProps.value.value[start] = getAliasTimeValue(sTime, 'start');
      refsProps.value.value[end] = getAliasTimeValue(eTime, 'end');
      onChange(dateProps);
    };
    const onClear = async () => {
      try {
        await ElMessageBox.confirm('清空筛选项将把所有筛选项删除，确认要清空筛选项吗？', '清空筛选项');
        refsProps.filters.value.forEach(i => {
          const data = refsProps.value.value;
          if (i.defaultValue) {
            data[i.key] = i.defaultValue;
          } else {
            // filter 目前仅支持数组和字符串类型、如有其他场景再做扩增
            data[i.key] = Array.isArray(data[i.key]) ? [] : '';
          }
        });
        onChange();
        emit('clear');
      } catch (error) {}
    };
    const getItemRender = item => {
      if (item.type === 'custom') {
        return typeof item.render === 'function' ? item.render({
          onChange
        }) : item.render;
      }
      return '';
    };
    return {
      onChange,
      onChangeDate,
      onClear,
      getItemRender
    };
  }
});