import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  class: "t-table-operation-column-cell"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$$attrs$fixed, _ctx$$attrs;
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table_column = _resolveComponent("el-table-column");
  return _openBlock(), _createBlock(_component_el_table_column, _mergeProps(_ctx.$attrs, {
    ref: "columnRef",
    label: _ctx.label,
    width: _ctx.computedWidth,
    fixed: (_ctx$$attrs$fixed = (_ctx$$attrs = _ctx.$attrs) === null || _ctx$$attrs === void 0 ? void 0 : _ctx$$attrs.fixed) !== null && _ctx$$attrs$fixed !== void 0 ? _ctx$$attrs$fixed : 'right'
  }), {
    default: _withCtx(({
      row,
      column,
      $index
    }) => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index) => {
      return _openBlock(), _createBlock(_component_el_button, {
        key: index,
        type: "",
        text: "",
        disabled: _ctx.shouldDisabled(button, row),
        loading: _ctx.shouldLoading(button, row),
        onClick: $event => _ctx.handleButtonClick(button, row)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(button.text), 1)]),
        _: 2
      }, 1032, ["disabled", "loading", "onClick"]);
    }), 128)), _renderSlot(_ctx.$slots, "default", {
      row: row,
      column: column,
      index: $index
    })])]),
    _: 3
  }, 16, ["label", "width", "fixed"]);
}