import * as PATHS from './index';
import {IOptionItem, IPageCfg} from '@/base/api/interface/global';

export interface ICompanyContact {
    /**
     * 联系人
     */
    contact_person: string;
    /**
     * 职称
     */
    job_title: string;
    /**
     * 联系人电话
     */
    phone: string[];
    /**
     * 联系人序号
     */
    no: number;
    /**
     * 是否有邮箱（全球买家数据库）
     */
    has_email: 0 | 1;
    /**
     * linkedin 链接（全球买家数据库）
     */
    linkedin: string;
    /**
     * 邮箱（美国买家数据库）
     */
    emails?: string[];
    /**
     * 社媒（美国买家数据库）
     */
    social_medias?: string[];
}

export interface ICompanyDetail {
    /**
     * 网址
     */
    website: string;
    /**
     * 一级地址
     */
    address1: string;
    /**
     * 二级地址
     */
    address2: string;
    /**
     * 三级地址
     */
    address3: string;
    /**
     * 产品类别
     */
    classify: string;
    /**
     * 一级sic名
     */
    category: string;
    /**
     * 二级sic名
     */
    category2: string;
    /**
     * 三级sic名
     */
    category3: string;
    /**
     * 四级sic名
     */
    category4: string;
    /**
     * 一级sic编码
     */
    sic: string;
    /**
     * 二级sic编码
     */
    sic2: string;
    /**
     * 三级sic编码
     */
    sic3: string;
    /**
     * 四级sic编码
     */
    sic4: string;
    /**
     * 成立时间
     */
    establish_date: string;
    /**
     * 公司名称
     */
    company_name: string;
    /**
     * 公司描述
     */
    description: string;
    /**
     * 员工总数
     */
    num_of_employees: string;
    /**
     * 年营业额
     */
    annual_revenue: string;
    /**
     * 公司 logo
     */
    logo: string;
    /**
     * 联系人
     */
    contact: ICompanyContact[];
    /**
     * 营业时间
     */
    business_hours: string[];
    /**
     * 社交媒体
     */
    social_media: string[];
    /**
     * 邮箱
     */
    emails: string[];
    /**
     * 电话
     */
    phones: string[];
    /**
     * 传真
     */
    fax: string[];
    /**
     * TODO: 产品介绍 待校对
     */
    product: string[];
    /**
     * TODO: 国家 待校对
     */
    area: string[];
}

/**
 * 当前公司的挖掘状态
 * 从未挖掘过: -1
 * 未开始：0
 * 挖掘中：1
 * 挖掘完成：2
 * 未挖掘到联系人：3
 */
export enum COMPANY_MINING_STATUS {
    NEVER = -1,
    INIT = 0,
    PENDING = 1,
    FINISH = 2,
    FAILED = 3,
}

/**
 * 是否导入CRM
 * 未导入: 0
 * 已导入: 1
 */
export enum CRM_STATUS {
    NOT_IMPORT = 0,
    IMPORTED = 1,
}

export interface IUsCompanyListItem {
    companyId: string;
    /**
     * 公司名
     */
    companyName: string;
    /**
     * 年销售额
     */
    salesAmount: string;
    /**
     * 公司logo
     */
    companyLogo: string;
    /**
     * 员工数据
     */
    employeeCount: string;
    /**
     * 成立时间
     */
    establishTime: number;
    /**
     * 挖掘到的联系人个数
     */
    contactsCount: number;
    /**
     * 所属行业
     */
    fullSic: string;
    /**
     * 挖掘状态
     */
    miningStatus?: COMPANY_MINING_STATUS;
    /**
     * 检索出该公司的推荐理由
     */
    recommendReason?: string;
    /**
     * 是否导入CRM
     */
    crmStatus: CRM_STATUS;
}

/**
 * 美国买家数据库-公司详情页-海关数据（采购信息）
 */
export interface ICompanyCustomsItem {
    date: string; // 日期
    consigneeName: string; // 收货方
    shipperName: string; // 发货方
    productDesc: string; // 交易详情
}

export interface IBuyerDbCompanyApi {
    [PATHS.GET_BUYERDB_COMPANY_FILTER_ITEMS_API]: {
        req: never;
        res: {
            establishTimeList: IOptionItem[];
            employeeCountList: IOptionItem[];
        };
    };
    [PATHS.GET_BUYERDB_COMPANIES]: {
        req: {
            /**
             * 潜客行业
             */
            sic: string;
            /**
             * 主营产品
             */
            product: string;
            /**
             * 成立时间
             */
            establishTime: number | string;
            /**
             * 员工数量范围
             */
            employeeCount: string;
            /**
             * 产品名称或公司名称
             */
            buyerProduct: string;
            /**
             * 过滤挖掘状态
             * 0-全部 1-未挖掘联系人 2-联系人挖掘成功 3-联系人挖掘中 4-未挖掘到联系人
             * // 使用逗号隔开数字拼接成字符串（因界面为单选，所以暂时不需要拼接）
             */
            filterMining: number | string;
            /**
             * 是否过滤导入CRM: 0-不过滤 1-已导入 2-未导入
             */
            filterCRM: number | string;
        } & IPageCfg;
        res: {
            total: number;
            recordList: IUsCompanyListItem[];
            message?: string;
        };
    };
    [PATHS.GET_US_BUYERDB_COMPANY_DETAIL]: {
        req: {
            companyId: IUsCompanyListItem['companyId'];
            fc: number;
        };
        res: ICompanyDetail & {
            companyId: string;
        };
    };
    [PATHS.GET_BUYERDB_COMPANY_CUSTOMS_DATA]: {
        req: {
            companyId: IUsCompanyListItem['companyId'];
        } & IPageCfg;
        res: {
            total: number;
            recordList: ICompanyCustomsItem[];
        };
    };
}
