import {ISicSugCache, ISicSugFullItem, IUsCompanyListFilterData} from './types';

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_US_COMPANY_LIST_FILTER_DATA: IUsCompanyListFilterData = {
    product: undefined,
    sic: undefined,
    employeeCount: undefined,
    establishTime: undefined,
    subsic: undefined, // 筛选子行业
    buyerProduct: undefined,
    filterMining: undefined,
    filterCRM: undefined,
};

/**
 * 美国买家数据库行业信息缓存键
 */
export const CACHE_KEY_US_CUSTOMS_DATA_SIC_SUG = 'US_CUSTOMS_DATA_SIC_SUG';

/**
 * 美国买家数据库默认行业信息（包括附加信息）
 */
export const DEFAULT_US_CUSTOMS_DATA_SIC_SUG_FULL_ITEM: ISicSugFullItem = {
    sic: '',
    category: '',
    id: 0,
    level: 0,
    extraInfo: {},
};

/**
 * 美国买家数据库默认行业信息缓存
 */
export const DEFAULT_US_CUSTOMS_DATA_SIC_SUG_CACHE: ISicSugCache = {
    0: {
        ...DEFAULT_US_CUSTOMS_DATA_SIC_SUG_FULL_ITEM,
        extraInfo: {
            childIds: [],
            isAllChildIds: false,
        },
    },
};
